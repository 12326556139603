export const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
export const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
export const FIREBASE_DATABASEURL = process.env.REACT_APP_FIREBASE_DATABASEURL;
export const FIREBASE_PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID;
export const FIREBASE_STORAGEBUCKET = process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
export const FIREBASE_MESSAGINGSENDERID = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
export const FIREBASE_APPID = process.env.REACT_APP_FIREBASE_APPID;
export const FIREBASE_MEASUREMENTID = process.env.REACT_APP_FIREBASE_MEASUREMENTID;

export const API_URL = process.env.REACT_APP_API_URL;

export const ROUTE_HOME = '/';
export const ROUTE_CLIPBOARDS = '/clipboards';

export const LOGGED_IN_KEY = 'loggedIn';
